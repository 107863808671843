// extracted by mini-css-extract-plugin
export var afterCaption = "maine_digest_2021_10-module--afterCaption--c1ab3";
export var columnWrapper = "maine_digest_2021_10-module--columnWrapper--86867";
export var dateline = "maine_digest_2021_10-module--dateline--d8384";
export var heading = "maine_digest_2021_10-module--heading--d8c06";
export var headingLogo = "maine_digest_2021_10-module--headingLogo--93e58";
export var headingWrapper = "maine_digest_2021_10-module--headingWrapper--d4d5d";
export var heroImage = "maine_digest_2021_10-module--heroImage--8b821";
export var instance = "maine_digest_2021_10-module--instance--da463";
export var latestnewsarticleheadline = "maine_digest_2021_10-module--latestnewsarticleheadline--8bdd6";
export var leftPhoto = "maine_digest_2021_10-module--leftPhoto--71774";
export var leftPhotoCaption = "maine_digest_2021_10-module--leftPhotoCaption--7e590";
export var maineDigest = "maine_digest_2021_10-module--maineDigest--dfe8a";
export var photoCaption = "maine_digest_2021_10-module--photoCaption--7a85b";
export var photosWrapper = "maine_digest_2021_10-module--photosWrapper--f8d81";
export var rightCaptions = "maine_digest_2021_10-module--rightCaptions--90e7a";
export var rightPhoto = "maine_digest_2021_10-module--rightPhoto--742b5";
export var rightPhotoCaption = "maine_digest_2021_10-module--rightPhotoCaption--6b4e7";
export var subheading = "maine_digest_2021_10-module--subheading--e3f10";
export var textWrapper = "maine_digest_2021_10-module--textWrapper--0667d";
export var whatsNew = "maine_digest_2021_10-module--whatsNew--1cae1";